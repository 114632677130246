<script setup>
import CodeEditor from "@/components/TextTemplates/Components/CodeEditor.vue";
import Header from "@/components/Tools/Header/Header.vue";
import { useRoute, useRouter } from "vue-router/composables";
import { computed, getCurrentInstance, onMounted, ref } from "vue";
import CustomFunctions from "@/components/CustomFunctions/customFunctions";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { useStore } from "@/core/services/store";
import i18n from "@/core/plugins/vue-i18n";
import { Error } from "@/core/plugins/swal";
import Modal from "@/components/Tools/Modal.vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { useWorkNote } from "@/composables/useWorkNote";

const LOADING_KEY = "loadCustomFunction";
const LOADING_KEY_SAVE = "saveCustomFunction";
const LOADING_KEY_TEST = "testCustomFunction";
const route = useRoute();
const router = useRouter();
const store = useStore();
const customFunctionId = computed(() => route.params?.id);
const customFunction = ref();
const codeEditor = ref();
const toast = getCurrentInstance().proxy.$toast;
const showTestModal = ref(false);
const testFormRef = ref();

const testFormModel = ref();
const testResult = ref();
const testFormConfig = ref({
  label: "",
  enableVariables: false,
  enableTypecast: true,
  labelColClass: "col-5",
  fieldColClass: "col-7",
  fieldColClassXl: "col-7"
});
const testForm = computed(() => {
  const fields = [];

  const fieldTypes = {
    string: "text",
    number: "number",
    boolean: "checkbox",
    object: "code"
  };

  for (const param of customFunction.value?.parameters || []) {
    const type = fieldTypes?.[param.type] ?? "text";

    fields.push({
      name: param.name,
      label: param.name,
      type: type,
      validations: {
        required: !param.nullable
      },
      ...(type === "code"
        ? {
            lang: "json",
            showLineNumbers: false,
            style: "dark"
          }
        : {})
    });
  }

  return fields;
});
const isPresetProject = computed(() => store.getters.isPresetProject);
const isDevPresetVersion = computed(() => store.getters.isDevPresetVersion);
const headerButtons = computed(() => {
  let buttons = [
    {
      type: "button",
      icon: store.getters["config/icons"].play,
      tooltip: i18n.t("customFunctions.test"),
      emit: "test",
      disabledWhenLoading: true
    }
  ];

  if (isPresetProject.value || !isDevPresetVersion.value) {
    return buttons;
  }

  buttons.splice(1, 0, {
    type: "button",
    icon: store.getters["config/icons"].save,
    tooltip: i18n.t("general.save"),
    emit: "save",
    disabledWhenLoading: true
  });
  return buttons;
});

async function getCustomFunction() {
  addEventToLoadingQueue({ key: LOADING_KEY });

  if (!customFunctionId.value) {
    await router.push({ name: "projectCustomFunctions" });

    return;
  }

  const response = await CustomFunctions.get(customFunctionId.value);
  customFunction.value = response?.data ?? {};

  removeEventFromLoadingQueue({ key: LOADING_KEY });
}

async function onSave() {
  addEventToLoadingQueue({ key: LOADING_KEY_SAVE });

  try {
    const { addWorkNote } = useWorkNote();
    const { data, success } = await addWorkNote(customFunction.value);

    if (!success) {
      return;
    }

    customFunction.value = data;

    await CustomFunctions.update(customFunctionId.value, customFunction.value);

    toast.fire({
      icon: "success",
      title: i18n.t("customFunctions.saved")
    });
  } catch (e) {
    Error(e);
  } finally {
    removeEventFromLoadingQueue({ key: LOADING_KEY_SAVE });
  }
}

function onOpenTest() {
  testResult.value = "";
  showTestModal.value = true;
}

async function onTest() {
  if (testFormRef.value?.validate() !== true) {
    return;
  }

  addEventToLoadingQueue({ key: LOADING_KEY_TEST });

  try {
    const values = {};

    for (const value of Object.keys(testFormModel.value || {})) {
      const param = customFunction.value?.parameters?.find(
        p => p.name === value
      );

      if (param.type !== "object") {
        values[value] = testFormModel.value[value];

        continue;
      }

      values[value] = JSON.parse(testFormModel.value[value]);
    }

    const result = await CustomFunctions.execute(customFunctionId.value, {
      ...customFunction.value,
      values: values
    });

    if (!result?.data?.success) {
      Error(i18n.t("customFunctions.error", { error: result?.data?.error }));
      removeEventFromLoadingQueue({ key: LOADING_KEY_TEST });

      return;
    }

    testResult.value = JSON.stringify(result?.data?.parsedResult);

    toast.fire({
      icon: "success",
      title: i18n.t("customFunctions.tested")
    });
  } catch (e) {
    Error(e);
  } finally {
    removeEventFromLoadingQueue({ key: LOADING_KEY_TEST });
  }
}

function insertText(param) {
  const editor = codeEditor.value.getEditor();
  const doc = editor.getDoc();
  const cursor = doc.getCursor();
  doc.replaceRange(param.name, cursor);
}

onMounted(async () => {
  await getCustomFunction();
});
</script>

<template>
  <div v-if="customFunction" class="h-100 d-flex flex-column">
    <!------------ START: Header ------------>
    <Header
      :title="
        $t('customFunctions.edit', { name: customFunction.name }).toString()
      "
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectCustomFunctions' })"
      @save="onSave"
      @test="onOpenTest"
    />
    <!------------ END: Header ------------>
    <div class="card-body p-0">
      <div class="row mx-n1 h-100">
        <div class="col-lg-10 col-md-8 col-12">
          <div class="card card-custom h-100">
            <div class="card-body">
              <CodeEditor
                ref="codeEditor"
                v-model="customFunction.body"
                mode="javascript"
                :show-mode-badge="true"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-4 col-12">
          <div class="card card-custom">
            <div class="card-body">
              <div class="custom-function-parameters">
                <h4 class="w-100">{{ $t("customFunctions.variables") }}</h4>

                <button
                  v-for="param in customFunction.parameters"
                  :key="param.name"
                  v-b-popover.hover="
                    i18n.t('customFunctions.insertVariable', {
                      name: param.name
                    })
                  "
                  class="custom-function-parameter"
                  @pointerdown.prevent
                  @click="insertText(param)"
                >
                  <span class="custom-function-parameter__name"
                    >{{ param.name }}:</span
                  >
                  <span class="custom-function-parameter__type">{{
                    param.type
                  }}</span>
                </button>

                <div class="custom-function-parameters__help">
                  {{ $t("customFunctions.parametersHelp") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal
      type="x-large"
      :show-modal="showTestModal"
      :title="i18n.t('customFunctions.test').toString()"
      @toggle-modal="showTestModal = $event"
    >
      <template #content>
        <div class="row">
          <div class="col-6">
            <FormHelper
              ref="testFormRef"
              v-model="testFormModel"
              class="test-form"
              :form="testForm"
              :config="testFormConfig"
            ></FormHelper>
          </div>

          <div class="col-6">
            <h5>{{ $t("customFunctions.testResult") }}</h5>

            <CodeEditor
              v-model="testResult"
              mode="javascript"
              :additional-options="{ readOnly: true }"
            />
          </div>
        </div>
      </template>

      <template #footer>
        <button class="btn btn-primary" @click="onTest">
          {{ $t("customFunctions.testExecute") }}
        </button>
      </template>
    </Modal>
  </div>
</template>

<style scoped lang="scss">
.custom-function-parameters {
  margin: 0 1rem 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  &__help {
    margin-top: 20px;
    font-size: 12px;
    font-style: italic;
  }
}

.custom-function-parameter {
  border-radius: 8px;
  padding: 6px 12px;
  background-color: $color-connect-primary-light;
  color: $color-connect-primary;
  transition: 0.2s;

  &:hover {
    background-color: $color-connect-primary;
    color: $color-connect-white;
  }

  &__name {
    font-weight: 500;
  }

  &__type {
    margin-left: 5px;
    font-style: italic;
  }
}

.test-form {
  :deep(.CodeMirror) {
    margin-left: 0;
    margin-right: 0;

    .CodeMirror-scroll {
      min-height: 150px;
    }
  }
}
</style>
